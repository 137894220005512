.c-table p {
  margin-bottom: 0; }

.nav-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5px !important;
  padding-bottom: 50px; }
  .nav-buttons > * {
    margin: 0 12px; }

.c-table__header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 4px; }
  .c-table__header .c-table__row {
    padding: 0;
    margin: 12px 0 18px;
    background-color: transparent; }

.c-table__caption {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase; }

.c-table__row {
  padding: 10px 0;
  margin: 8px 0;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.03); }

.c-table__block {
  margin: 16px 0; }
  .c-table__block .c-table__row {
    padding: 10px 20px; }

.c-table__sm-heading {
  margin: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5); }

.order-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 35px;
  margin-bottom: 0px; }
  .order-steps li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin: 0 12px 4px !important;
    padding: 5px 18px;
    border-radius: 24px;
    border: solid 1px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    font-size: 14px;
    line-height: normal;
    color: rgba(0, 0, 0, 0.25);
    list-style-type: none; }
    .order-steps li.is-active {
      border-color: transparent;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);
      color: #149566; }
    .order-steps li.is-enabled {
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);
      border-color: transparent;
      color: rgba(0, 0, 0, 0.5); }

.order-step__numb {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px; }

.section-disabled {
  pointer-events: none;
  opacity: 0.4; }
  .section-disabled * {
    color: rgba(0, 0, 0, 0.5) !important;
    border-color: rgba(0, 0, 0, 0.2) !important; }
  .section-disabled .anticon, .section-disabled .icon {
    fill: rgba(0, 0, 0, 0.5) !important;
    color: rgba(0, 0, 0, 0.5) !important; }

.radio-boxes {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }
  .radio-boxes .ant-radio-group {
    display: flex;
    justify-content: space-between; }
    .radio-boxes .ant-radio-group .ant-radio {
      display: none; }
    .radio-boxes .ant-radio-group .ant-radio-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 112px;
      padding: 18px;
      margin: 12.5px 0;
      border: solid 1px rgba(0, 0, 0, 0.2);
      color: rgba(0, 0, 0, 0.2);
      width: 184px; }
      .radio-boxes .ant-radio-group .ant-radio-wrapper span {
        font-weight: 700;
        max-width: 120px;
        white-space: normal;
        font-size: 14px;
        padding: 0; }
      .radio-boxes .ant-radio-group .ant-radio-wrapper.ant-radio-wrapper-disabled {
        cursor: not-allowed; }
      .radio-boxes .ant-radio-group .ant-radio-wrapper.ant-radio-wrapper-checked {
        background-color: rgba(1, 139, 20, 0.07);
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
        border-color: transparent;
        color: #149566 !important; }
        .radio-boxes .ant-radio-group .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-disabled + span {
          color: #149566 !important;
          cursor: default !important; }
        .radio-boxes .ant-radio-group .ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-disabled {
          cursor: default; }

.radio-label-sign {
  margin: 0 25px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5); }

.radio-label-text {
  font-size: 14px; }
