.customer-scripts-container {

    h3 {
        margin-bottom: 16px;
    }

    .header-container {
        display: flex;
        flex-wrap: wrap;
        gap: 80px;
        margin-bottom: 30px;

        .change-customer-section {

            .change-customer {
                display: flex;
                flex-direction: column;

                label {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.5;
                    color: rgb(113, 128, 150);
                    margin: 0px;
                    margin-bottom: 8px;
                }

                input {
                    width: 150px;
                    border-radius: 4px;
                    padding: 10px;
                    border: 1px solid rgb(158, 158, 158);
                    background-color: #fff;

                    &:focus {
                        outline: none;
                        border: 1px solid rgb(20, 149, 102);
                    }
                }

                button {
                    background-color: #fff;
                    border-radius: 12px;
                    border: 1px solid rgb(20, 149, 102);
                    font-size: 10px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: normal;
                    text-align: center;
                    color: rgb(20, 149, 102);
                    height: 24px;
                    width: 40px;
                    margin-left: 12px;
                }
            }
        }

        .customer-details-section {
            flex: 1;

            .spin-container {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .07);
                border-radius: 4px;
            }

            .customer-scripts-details-table {
                border-radius: 4px;
                background-color: #fff;
                box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .07);

                thead {
                    border-top: none;

                    tr {
                        height: 50px;

                        th {

                            &:first-child {
                                padding-left: 20px;
                            }

                            &:last-child {
                                padding-right: 20px;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        background-color: rgba(0, 0, 0, .03);
                        border-top: none;
                        height: 50px;

                        td {
                            color: #2d3748;

                            &:first-child {
                                padding-left: 20px;
                            }

                            &:last-child {
                                padding-right: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .orders-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
        }
    }

    .script-processing-container {
        width: 100%;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .07);

        .orders-headers {
            display: flex;
            align-items: center;
            height: 50px;

            h5 {
                padding: 5px;
                font-size: 10px;
                font-weight: bold;
                color: #a0aec0;
                text-transform: uppercase;
                margin: 0;

                &:first-child {
                    padding-left: 20px;
                }

                &:last-child {
                    padding-right: 20px;
                }
            }
        }

        .row-container {
            display: flex;
            align-items: center;
            padding: 5px 0;

            span {
                padding: 5px;


                &:first-child {
                    padding-left: 20px;
                }

                &:last-child {
                    padding-right: 20px;
                }
            }

            .items-wrapper {
                display: flex;
                flex-direction: column;

                .item-wrapper {
                    flex: 1;
                    display: flex;
                    align-items: center;

                    .icon-wrapper {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .oddRow {
            background-color: #fff;
        }

        .evenRow {
            background-color: rgba(0, 0, 0, .03);
        }
    }
}