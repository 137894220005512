.loginform-wrap {
  display: flex;
  min-height: 100vh;
  font-size: 12px;
  text-align: center; }
  .loginform-wrap .ant-form-vertical .ant-form-item {
    margin-bottom: 24px; }
  .loginform-wrap .ant-input {
    height: 48px; }

.loginform-container {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  padding: 104px 15px 40px;
  background-color: #fcfcfc; }
  @media only screen and (min-width: 580px) {
    .loginform-container {
      max-width: 492px; } }

.loginform-backdrop {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  padding: 40px 24px 24px;
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff; }

.loginform__forgotten {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #149566; }

.loginform-aside {
  display: flex;
  align-items: center;
  width: 34vw;
  max-width: 440px;
  background-color: #149566; }

.loginform__illustr {
  transform: translateX(-14%); }

.form-wrap {
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  text-align: center; }

@media only screen and (max-width: 580px) {
  .loginform-aside {
    display: none; } }
