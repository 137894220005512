.breadcrumb {
  background-color: #149566;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 14px 30px; }

.breadcrumb__row {
  align-items: center;
  display: flex; }
  .breadcrumb__row, .breadcrumb__row a {
    color: #fff; }

.breadcrumb__part {
  align-items: center;
  display: inline-flex;
  font-weight: 400; }

.breadcrumb__part:first-child .arrow-separator {
  display: none; }

.breadcrumb__part:last-child .underlined {
  text-decoration: none !important;
  font-weight: 600;
  pointer-events: none;
  cursor: default; }
