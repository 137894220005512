@charset "UTF-8";
.icon-admin {
  width: 0.83em;
  height: 1em;
  fill: none; }

.icon-arrow-down {
  width: 1em;
  height: 1em;
  fill: #000000; }

.icon-arrow-left {
  width: 1em;
  height: 1em; }

.icon-arrow-right {
  width: 1em;
  height: 1em; }

.icon-at {
  width: 0.94em;
  height: 1em; }

.icon-active {
  width: 1em;
  height: 1em;
  fill: currentColor;
  stroke: currentColor; }

.icon-pause {
  width: 1em;
  height: 1em;
  font-size: 16px !important;
  fill: none;
  stroke: currentColor; }

.icon-cancel {
  width: 1em;
  height: 1em; }

.icon-play {
  width: 1em;
  height: 0.88em;
  stroke: currentColor;
  font-size: 18px; }

.icon-split {
  width: 0.727em;
  height: 1em;
  font-size: 20px; }

.icon-auto-order {
  width: 1em;
  height: 1em;
  fill: #000000; }

.icon-upload {
  width: 1.375em;
  height: 1em;
  fill: #000000; }

.icon-bell {
  width: 0.84em;
  height: 1em;
  fill: #000000; }

.icon-calendar {
  width: 0.95em;
  height: 1em;
  fill: #000000;
  font-size: 20px; }

.icon-check {
  width: 1.08em;
  height: 1em;
  fill: none; }

.icon-choosed {
  width: 1.11em;
  height: 1em;
  fill: none; }

.icon-close {
  width: 1em;
  height: 1em;
  fill: #000000; }

.icon-customers {
  width: 1.26em;
  height: 1em;
  fill: initial; }

.icon-log-out {
  width: 1em;
  height: 1em;
  fill: none; }

.icon-mail {
  width: 1.41em;
  height: 1em; }

.icon-menu {
  width: 1em;
  height: 1em;
  fill: #000000; }

.icon-assign, .icon-check {
  width: 1em;
  height: 1em;
  fill: #000000; }

.icon-check {
  font-size: 24px;
  fill: #149566; }

.icon-download {
  width: 1em;
  height: 0.76em;
  fill: #000000; }

.icon-new-order {
  width: 1em;
  height: 1em;
  fill: none; }

.icon-order {
  width: 1.05em;
  height: 1em;
  fill: #b2b2b2; }

.icon-phone {
  width: 0.61em;
  height: 1em; }

.icon-report {
  width: 0.82em;
  height: 1em;
  fill: none; }

.icon-save {
  width: 1em;
  height: 1em;
  fill: #000000; }

.icon-edit {
  width: 0.94em;
  height: 1em;
  fill: #000000; }

.icon-search {
  width: 0.95em;
  height: 1em;
  fill: red; }

.icon-sticky-note {
  width: 1em;
  height: 1em;
  fill: #000000; }

.icon-warning {
  width: 1.15em;
  height: 1em;
  fill: none; }

.customer-scripts-container h3 {
  margin-bottom: 16px; }

.customer-scripts-container .header-container {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
  margin-bottom: 30px; }
  .customer-scripts-container .header-container .change-customer-section .change-customer {
    display: flex;
    flex-direction: column; }
    .customer-scripts-container .header-container .change-customer-section .change-customer label {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.5;
      color: #718096;
      margin: 0px;
      margin-bottom: 8px; }
    .customer-scripts-container .header-container .change-customer-section .change-customer input {
      width: 150px;
      border-radius: 4px;
      padding: 10px;
      border: 1px solid #9e9e9e;
      background-color: #fff; }
      .customer-scripts-container .header-container .change-customer-section .change-customer input:focus {
        outline: none;
        border: 1px solid #149566; }
    .customer-scripts-container .header-container .change-customer-section .change-customer button {
      background-color: #fff;
      border-radius: 12px;
      border: 1px solid #149566;
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #149566;
      height: 24px;
      width: 40px;
      margin-left: 12px; }
  .customer-scripts-container .header-container .customer-details-section {
    flex: 1; }
    .customer-scripts-container .header-container .customer-details-section .spin-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);
      border-radius: 4px; }
    .customer-scripts-container .header-container .customer-details-section .customer-scripts-details-table {
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07); }
      .customer-scripts-container .header-container .customer-details-section .customer-scripts-details-table thead {
        border-top: none; }
        .customer-scripts-container .header-container .customer-details-section .customer-scripts-details-table thead tr {
          height: 50px; }
          .customer-scripts-container .header-container .customer-details-section .customer-scripts-details-table thead tr th:first-child {
            padding-left: 20px; }
          .customer-scripts-container .header-container .customer-details-section .customer-scripts-details-table thead tr th:last-child {
            padding-right: 20px; }
      .customer-scripts-container .header-container .customer-details-section .customer-scripts-details-table tbody tr {
        background-color: rgba(0, 0, 0, 0.03);
        border-top: none;
        height: 50px; }
        .customer-scripts-container .header-container .customer-details-section .customer-scripts-details-table tbody tr td {
          color: #2d3748; }
          .customer-scripts-container .header-container .customer-details-section .customer-scripts-details-table tbody tr td:first-child {
            padding-left: 20px; }
          .customer-scripts-container .header-container .customer-details-section .customer-scripts-details-table tbody tr td:last-child {
            padding-right: 20px; }

.customer-scripts-container .orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px; }
  .customer-scripts-container .orders-header h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0; }

.customer-scripts-container .script-processing-container {
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07); }
  .customer-scripts-container .script-processing-container .orders-headers {
    display: flex;
    align-items: center;
    height: 50px; }
    .customer-scripts-container .script-processing-container .orders-headers h5 {
      padding: 5px;
      font-size: 10px;
      font-weight: bold;
      color: #a0aec0;
      text-transform: uppercase;
      margin: 0; }
      .customer-scripts-container .script-processing-container .orders-headers h5:first-child {
        padding-left: 20px; }
      .customer-scripts-container .script-processing-container .orders-headers h5:last-child {
        padding-right: 20px; }
  .customer-scripts-container .script-processing-container .row-container {
    display: flex;
    align-items: center;
    padding: 5px 0; }
    .customer-scripts-container .script-processing-container .row-container span {
      padding: 5px; }
      .customer-scripts-container .script-processing-container .row-container span:first-child {
        padding-left: 20px; }
      .customer-scripts-container .script-processing-container .row-container span:last-child {
        padding-right: 20px; }
    .customer-scripts-container .script-processing-container .row-container .items-wrapper {
      display: flex;
      flex-direction: column; }
      .customer-scripts-container .script-processing-container .row-container .items-wrapper .item-wrapper {
        flex: 1;
        display: flex;
        align-items: center; }
        .customer-scripts-container .script-processing-container .row-container .items-wrapper .item-wrapper .icon-wrapper {
          display: flex;
          align-items: center; }
  .customer-scripts-container .script-processing-container .oddRow {
    background-color: #fff; }
  .customer-scripts-container .script-processing-container .evenRow {
    background-color: rgba(0, 0, 0, 0.03); }

.scripts-modal-container .scripts-processing-header {
  margin-bottom: 20px; }
  .scripts-modal-container .scripts-processing-header label {
    margin-right: 8px;
    color: #2d3748; }

.scripts-modal-container .scripts-rows-container {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07); }
  .scripts-modal-container .scripts-rows-container .scripts-header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px; }
    .scripts-modal-container .scripts-rows-container .scripts-header h5 {
      padding: 5px 10px;
      font-size: 10px;
      font-weight: bold;
      color: #a0aec0;
      text-transform: uppercase;
      margin: 0; }
      .scripts-modal-container .scripts-rows-container .scripts-header h5:first-child {
        padding-left: 20px; }
      .scripts-modal-container .scripts-rows-container .scripts-header h5:last-child {
        padding-right: 20px; }
  .scripts-modal-container .scripts-rows-container .scripts-body {
    width: 100%;
    margin-bottom: 30px; }
    .scripts-modal-container .scripts-rows-container .scripts-body .script-row {
      width: 100%;
      display: flex;
      align-items: center; }
      .scripts-modal-container .scripts-rows-container .scripts-body .script-row .item-wrapper {
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        align-items: center; }
        .scripts-modal-container .scripts-rows-container .scripts-body .script-row .item-wrapper:first-child {
          padding-left: 20px; }
        .scripts-modal-container .scripts-rows-container .scripts-body .script-row .item-wrapper:last-child {
          padding-right: 20px; }
      .scripts-modal-container .scripts-rows-container .scripts-body .script-row .select-wrapper {
        padding: 10px; }
        .scripts-modal-container .scripts-rows-container .scripts-body .script-row .select-wrapper .ant-select-selection__rendered {
          margin-left: 10px; }
      .scripts-modal-container .scripts-rows-container .scripts-body .script-row:last-child {
        margin-bottom: 10px; }
      .scripts-modal-container .scripts-rows-container .scripts-body .script-row .ant-select-arrow-icon {
        font-size: 12px; }
      .scripts-modal-container .scripts-rows-container .scripts-body .script-row .ant-checkbox-wrapper {
        margin: 0; }
    .scripts-modal-container .scripts-rows-container .scripts-body .oddRow {
      background-color: #fff; }
    .scripts-modal-container .scripts-rows-container .scripts-body .evenRow {
      background-color: rgba(0, 0, 0, 0.03); }

.scripts-modal-container .scripts-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px; }

.order-summary-modal-container {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);
  border-radius: 4px; }
  .order-summary-modal-container .order-summary-header {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #f8f9fa; }
    .order-summary-modal-container .order-summary-header h3 {
      font-size: 10px;
      font-weight: bold;
      color: #a0aec0;
      text-transform: uppercase;
      margin: 0; }
      .order-summary-modal-container .order-summary-header h3:first-child {
        padding-left: 20px; }
      .order-summary-modal-container .order-summary-header h3:last-child {
        padding-right: 20px; }
    .order-summary-modal-container .order-summary-header .padding-left {
      padding-left: 6px; }
  .order-summary-modal-container .order-summary-body .summary-row {
    display: flex;
    padding: 5px 0; }
    .order-summary-modal-container .order-summary-body .summary-row .align-right {
      display: flex;
      justify-content: flex-end; }
    .order-summary-modal-container .order-summary-body .summary-row h5 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      padding-right: 6px; }
    .order-summary-modal-container .order-summary-body .summary-row .background-yellow {
      background-color: yellow; }
    .order-summary-modal-container .order-summary-body .summary-row .font-bold {
      font-weight: 600; }
    .order-summary-modal-container .order-summary-body .summary-row .padding-left {
      padding-left: 6px; }
  .order-summary-modal-container .order-summary-body .script-history-section {
    padding-bottom: 20px; }

body {
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  background-color: #fcfcfc; }

.hEACtv {
  border-top: 5px solid #1c1b1b !important; }

.alertify-logs {
  z-index: 999999; }
  .alertify-logs > .show {
    margin-top: 59px; }

.container {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 15px; }
  .container .container {
    padding: 0; }

.section-p-0 .section {
  padding: 0; }

.hidden {
  visibility: hidden; }

.pointer {
  cursor: pointer; }

.disabled-area {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed; }

.capitalise {
  text-transform: capitalize; }

button {
  outline: none; }
  button:hover {
    cursor: pointer; }

.link-green {
  font-size: 14px;
  color: #149566 !important;
  text-decoration: underline; }
  .link-green:hover, .link-green:focus {
    color: #17ab75; }

.separator {
  height: 10px;
  display: inline-block;
  width: 1px;
  margin: 0 9px;
  background-color: #4a4a4a; }

.divider {
  height: 1px;
  margin: 24px 0;
  background-color: rgba(0, 0, 0, 0.1); }

.arrow-separator {
  font-weight: 400;
  margin: 0 8px; }

.h-1,
.h-2,
.h-3,
.h-4,
.h-4-regular,
.h-4-bold,
.h-5,
.h-6 {
  margin: 0; }

.h-3 {
  font-size: 24px; }

.h-4 {
  font-size: 18px;
  font-weight: 600; }

.h-4-regular {
  font-size: 18px;
  font-weight: 400; }

.h-4-bold {
  font-size: 18px;
  font-weight: 700; }

.h-5 {
  font-size: 14px;
  font-weight: 600; }

.h-6 {
  font-size: 14px; }

.overflow-y-scroll {
  overflow-y: auto; }
  .overflow-y-scroll::-webkit-scrollbar {
    background-color: transparent;
    width: 8px; }
  .overflow-y-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    width: 8px;
    border-radius: 4px; }

.underlined,
.underlined > span {
  text-decoration: underline !important; }

.text-bold {
  font-weight: 700; }

.text-semibold {
  font-weight: 600; }

.text-center {
  text-align: center !important; }

.padding-right-20 {
  padding-right: 20px !important; }

.text-green {
  color: #149566; }

.fz-inherit {
  font-size: inherit; }

.fz-small {
  font-size: 12px; }
  .fz-small .ant-btn {
    font-size: inherit; }

.d-flex {
  display: flex; }

.flex-center {
  display: flex;
  align-items: center; }

.flex-center-middle {
  display: flex;
  justify-content: center;
  align-items: center; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.ant-form-item {
  font-family: 'Open Sans', sans-serif; }

.ant-form-item-required:before {
  display: none; }

.icon-choosed {
  fill: none !important;
  stroke: currentColor;
  stroke-width: 1px; }

.section {
  padding: 32px 0 32px; }
  .section .container > .ant-row > div {
    margin-bottom: 32px; }

.section-white {
  padding: 32px 40px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07); }
  .section-white.small-padding {
    padding: 18px 24px; }
  .section-white.large-padding {
    padding: 48px 24px; }

.section__container {
  padding-bottom: 30px; }

.section__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap; }
  .section__header p {
    margin: 0; }
  .section__header .h-4 {
    margin: 5px 24px 5px 0px; }

.section__label {
  font-size: 14px;
  color: #149566;
  margin: 5px 0px;
  font-style: italic; }
  .section__label:hover {
    color: #149566;
    text-decoration: underline; }

.section--bordered {
  padding: 20px 0 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.shrinked .ant-modal-body {
  padding: 30px 27px 43px !important; }

.shrinked .section__header {
  margin-bottom: 22px; }

.section-grey {
  padding: 22px 32px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.03); }

.modal-side-padding .ant-modal-body {
  padding: 44px 64px 10px; }
  @media only screen and (max-width: 840px) {
    .modal-side-padding .ant-modal-body {
      padding-left: 24px;
      padding-right: 24px; } }

.mt-5 {
  margin-top: 50px !important; }

.mt-4 {
  margin-top: 40px !important; }

.mt-3 {
  margin-top: 32px !important; }

.mt-2 {
  margin-top: 22px !important; }

.mt-1 {
  margin-top: 12.5px !important; }

.mr-1 {
  margin-right: 12.5px !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-05 {
  margin-bottom: 6px !important; }

.m-0 {
  margin: 0 !important; }

.mb-2 {
  margin-bottom: 24px !important; }

.p-0 {
  padding: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pb-1 {
  padding-bottom: 10px !important; }

.pt-1 {
  padding-top: 10px !important; }

.pb-2 {
  padding-bottom: 20px !important; }

.pt-2 {
  padding-top: 20px !important; }

.pb-4 {
  padding-bottom: 40px !important; }

.pt-4 {
  padding-top: 40px !important; }

.w-100 {
  width: 100%; }

.w-75 {
  width: 75%; }

.w-50 {
  width: 50%; }

.w-25 {
  width: 25%; }

.h-100 {
  height: 100%; }

.h-75 {
  height: 75%; }

.h-50 {
  height: 50%; }

.h-25 {
  height: 25%; }

.btn-bar {
  display: flex;
  justify-content: flex-end; }
  .btn-bar > *:not(:first-child) {
    margin-left: 16px; }

.form__notice {
  margin: 6px 0;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5); }

.light-table {
  padding: 6px 0;
  color: rgba(0, 0, 0, 0.75);
  font-size: 14px; }
  .light-table.overflow-y-scroll {
    height: 160px; }
  .light-table.bordered {
    margin: 16px 0 24px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.2); }
  .light-table.no-side-padding .table-cell {
    padding-left: 0;
    padding-right: 0; }
  .light-table .table-cell {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 4px 22px; }

.light-table__header {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5); }
  .light-table__header .table-cell {
    padding-top: 0;
    padding-bottom: 0; }

.form__search {
  margin: 6px 0; }
  .form__search input {
    height: 40px;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.2); }
    .form__search input::placeholder {
      color: rgba(0, 0, 0, 0.25); }
  .form__search .ant-input-search-icon {
    color: #149566;
    font-size: 18px; }

.form__inputnumber {
  width: 73px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1); }
  .form__inputnumber:hover {
    border-color: #149566 !important; }
  .form__inputnumber:disabled {
    color: #000;
    border-color: #d9d9d9 !important; }
  .form__inputnumber:focus {
    border-color: #149566 !important;
    box-shadow: 0 0 0 2px rgba(20, 149, 102, 0.2); }
  .form__inputnumber input {
    height: 40px;
    padding: 0 16px 0 16px;
    color: #000000; }
  .form__inputnumber .ant-input-number-handler-wrap {
    opacity: 1;
    border: none;
    height: 100%; }
  .form__inputnumber .ant-input-number-handler {
    border: none;
    height: 50% !important; }
    .form__inputnumber .ant-input-number-handler .anticon {
      color: #149566;
      font-size: 10px !important;
      transform: none !important; }

.form__swith {
  min-width: 58px;
  height: 32px;
  line-height: 32px;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 16px; }
  .form__swith.ant-switch-checked {
    background-color: #149566; }
  .form__swith.ant-switch-disabled {
    opacity: 0.8; }
  .form__swith .ant-switch-loading-icon, .form__swith::after {
    top: 1px;
    width: 28px;
    height: 28px;
    border-radius: 16px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.09); }
  .form__swith:active::before, .form__swith:active::after {
    width: 28px; }

.input-large,
.input-large input {
  height: 40px; }

.italic-grey {
  font-style: italic;
  color: rgba(0, 0, 0, 0.25); }

.italic-green {
  font-style: italic;
  color: #149566;
  text-decoration: underline; }

.light-grey {
  color: rgba(0, 0, 0, 0.5); }

.btn-action-close .icon {
  font-size: 24px;
  fill: #f03f55 !important; }

.double-arrow {
  display: flex; }
  .double-arrow .icon {
    font-size: 32px;
    margin: 0 -0.3em;
    fill: #b2b2b2; }

.stock {
  color: #000; }
  .stock .divider {
    margin: 16px -32px; }
  .stock .stock__action {
    min-width: 16px;
    min-width: 38px;
    display: inline-flex;
    justify-content: flex-end;
    order: 2; }
    .stock .stock__action .icon {
      font-size: 17px;
      fill: rgba(0, 0, 0, 0.3); }
      .stock .stock__action .icon:hover {
        fill: #149566; }

.stock-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0; }

.stock-row__value {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.11; }
  .stock-row__value > span {
    margin-right: 38px; }
  .stock-row__value .stock__action + span {
    margin-right: 0;
    order: 1; }

.stock-row__right {
  font-size: 18px; }
  .stock-row__right > span {
    padding-right: 13px; }
  .stock-row__right .ant-form-item {
    margin: 0;
    padding: 0; }
    .stock-row__right .ant-form-item input {
      text-align: right;
      padding-right: 13px; }

.ant-modal {
  top: 3% !important; }

.ant-modal-header + .ant-modal-body {
  padding-top: 24px; }

.ant-modal-body {
  padding-top: 45px; }

.ant-modal-close-x {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 16px; }

.ant-modal-close-icon {
  color: #149566; }

.ant-modal {
  color: #000; }
  .ant-modal .btn-bar {
    margin: 44px 0 30px; }
  .ant-modal p {
    margin: 0; }

.modal--copy .ant-modal-body {
  padding: 44px 24px 40px; }

.modal--copy .container {
  max-width: 640px; }

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.btn-empty {
  height: auto !important;
  min-width: 0 !important;
  padding: 0;
  background-color: transparent;
  border: none; }

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 14px;
  text-shadow: none;
  box-shadow: none; }
  .ant-btn:hover, .ant-btn:focus {
    border-color: #149566;
    color: #149566; }
  .ant-btn .icon {
    fill: currentColor; }
  .ant-btn .icon + span,
  .ant-btn span + .icon {
    margin-left: 8px; }
  .ant-btn.extrawide {
    width: 280px; }
  .ant-btn.wide {
    width: 220px; }
  .ant-btn.average {
    width: 200px; }
  .ant-btn.medium {
    width: 160px; }
  .ant-btn.small {
    width: 100%;
    max-width: 80px;
    min-width: 80px; }
  .ant-btn.transparent {
    border: none;
    border-radius: 0; }
    .ant-btn.transparent .icon {
      fill: #f8f6b0; }
    .ant-btn.transparent:hover {
      background-color: #107b54 !important;
      color: #fff; }
  .ant-btn.empty {
    border: none;
    padding: 0; }
  .ant-btn.grey {
    border-color: rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4); }

.ant-btn-primary {
  min-width: 116px;
  font-weight: 600;
  background-color: #149566;
  border: none;
  border-radius: 24px; }
  .ant-btn-primary:hover, .ant-btn-primary:active, .ant-btn-primary:focus {
    background-color: #107b54 !important;
    color: #fff; }
  .ant-btn-primary[disabled]:hover {
    background-color: #f5f5f5 !important; }

.ant-btn-default {
  border-color: #149566;
  background-color: transparent;
  color: #149566;
  min-width: 104px;
  border-radius: 24px; }
  .ant-btn-default:hover {
    color: #107b54;
    border-color: #107b54;
    background-color: transparent; }

a.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.ant-btn-lg {
  height: 56px; }

.ant-btn-sm {
  height: 40px; }

.ant-btn-xs {
  border-radius: 16px;
  height: 32px; }

.ant-tabs--styled {
  font-family: inherit; }
  .ant-tabs--styled .ant-tabs-bar {
    margin: 0;
    border-bottom: none;
    background-color: #fff;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);
    color: #149566;
    font-size: 14px;
    font-weight: 500; }
  .ant-tabs--styled .ant-tabs-nav-container {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 40px; }
  .ant-tabs--styled .ant-tabs-nav > div {
    display: flex; }
  .ant-tabs--styled .ant-tabs-nav .ant-tabs-tab {
    padding: 14px 0;
    flex-grow: 1;
    text-align: center;
    margin-right: 56px;
    color: rgba(0, 0, 0, 0.3); }
    .ant-tabs--styled .ant-tabs-nav .ant-tabs-tab:hover {
      color: #107b54; }
  .ant-tabs--styled .ant-tabs-nav .ant-tabs-tab-active {
    color: #149566;
    font-weight: 500; }
  .ant-tabs--styled .ant-tabs-ink-bar {
    background-color: #149566;
    opacity: 0.7;
    height: 4px; }

.table-wrap .icon-order {
  font-size: 25px; }

.table-wrap .icon-choosed {
  font-size: 18px; }

.table-wrap .icon-phone {
  font-size: 25px; }

.table-wrap .icon-mail {
  font-size: 17px; }

.table-wrap .icon-at {
  font-size: 16px; }

.table-wrap .icon-auto-order {
  font-size: 17px;
  fill: #149566; }

.ant-table {
  width: 100%;
  color: rgba(0, 0, 0, 0.75);
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07); }
  .ant-table .icon-order {
    font-size: 18px; }
  .ant-table .btn-bar {
    margin: unset; }

.table--nohead .ant-table-placeholder {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);
  border-bottom: none; }

.ant-table-body {
  overflow-x: auto; }

.ant-table-tbody > tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.03); }

.ant-table-tbody > tr td {
  border-bottom: none; }

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row),
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row),
.ant-table-thead > tr:hover:not(.ant-table-expanded-row),
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row),
.ant-table-thead > tr.row-selected,
.ant-table-tbody > tr.row-selected {
  background: rgba(20, 149, 102, 0.1) !important; }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr.row-selected > td,
  .ant-table-tbody > tr.row-selected > td {
    background: unset; }

.table--nohead thead th {
  padding: 0 !important;
  font-size: 0 !important; }
  .table--nohead thead th div {
    min-height: 0 !important; }

.table--nohead .ant-table-tbody > tr {
  background-color: transparent; }
  .table--nohead .ant-table-tbody > tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.03); }

.table--dropdown .ant-table {
  background-color: transparent;
  box-shadow: unset; }
  .table--dropdown .ant-table table {
    border-collapse: separate;
    border-spacing: 0 1em; }
    .table--dropdown .ant-table table tr.ant-table-row {
      background-color: #ffffff !important; }
    .table--dropdown .ant-table table tr.ant-table-expanded-row {
      background-color: #f7f7f7 !important; }
      .table--dropdown .ant-table table tr.ant-table-expanded-row td {
        padding-top: 10px;
        padding-bottom: 10px; }
      .table--dropdown .ant-table table tr.ant-table-expanded-row .table__expanded-row > div {
        width: 100%; }
        .table--dropdown .ant-table table tr.ant-table-expanded-row .table__expanded-row > div:not(:nth-child(2)) {
          width: 30%; }
        .table--dropdown .ant-table table tr.ant-table-expanded-row .table__expanded-row > div:first-child {
          width: 23.5%; }
        .table--dropdown .ant-table table tr.ant-table-expanded-row .table__expanded-row > div:last-child {
          text-align: right; }
      .table--dropdown .ant-table table tr.ant-table-expanded-row .ant-btn {
        height: unset; }
    .table--dropdown .ant-table table .ant-table-row-expand-icon-cell {
      width: 100px; }
      .table--dropdown .ant-table table .ant-table-row-expand-icon-cell .anticon {
        width: 24px;
        height: 24px; }

.ant-table-thead > tr > th {
  background: #fff;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: none; }
  .ant-table-thead > tr > th div {
    min-height: 40px;
    display: inline-flex;
    align-items: center; }

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px; }
  .ant-table-thead > tr > th:first-child,
  .ant-table-tbody > tr > td:first-child {
    padding-left: 20px; }

.table--expand .ant-table-thead > tr > th,
.table--expand .ant-table-tbody > tr > td {
  padding: 16px 24px; }

.table--expand .ant-table-tbody > tr:nth-child(odd) {
  background-color: transparent !important; }

.table--expand .ant-table-body {
  padding: 0 25px; }

.table--expand tr {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07); }

.ant-table-row-expand-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 30px;
  line-height: 1;
  text-align: center;
  background: transparent;
  border: none;
  color: #149566; }

.ant-table-expanded-row {
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);
  background-color: rgba(0, 0, 0, 0.03) !important; }

.table__expanded-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0; }

.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: hidden; }

.ant-spin-container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.custom-pagination {
  margin: 20px auto !important;
  text-align: center; }
  .custom-pagination.ant-pagination.ant-pagination-simple {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 24px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff; }
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-prev,
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-next {
      height: 48px; }
      .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
      .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
        height: 48px;
        padding: 10px 16px;
        border: none;
        background-color: transparent;
        border-radius: 0;
        display: inline-flex;
        align-items: center; }
      .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-prev:not(.ant-pagination-disabled) a,
      .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-next:not(.ant-pagination-disabled) a {
        color: #149566; }
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-next {
      margin-left: 24px; }
      .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
        border-left: 1px solid rgba(0, 0, 0, 0.06); }
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-prev {
      margin-right: 24px; }
      .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link {
        border-right: 1px solid rgba(0, 0, 0, 0.06); }
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
      font-size: 24px; }
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager,
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
      height: auto;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: #000; }
      .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager a,
      .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input a {
        color: inherit; }
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      border: solid 1px rgba(20, 149, 102, 0.5); }
    .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-slash {
      margin: 0 12px;
      position: relative;
      color: transparent; }
      .custom-pagination.ant-pagination.ant-pagination-simple .ant-pagination-slash:after {
        content: 'of';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        line-height: normal;
        color: rgba(0, 0, 0, 0.3); }

.table-iconset {
  display: flex;
  align-items: center;
  justify-content: center; }
  .table-iconset > * {
    margin: 0 8px; }

.table__first-column {
  padding-left: 35px !important; }

.table__topnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  min-height: 32px; }

form.ant-form-vertical .ant-form-item {
  margin-bottom: 8px; }

form.ant-form-vertical .ant-btn[type='submit'] {
  margin: 18px 0 6px; }

form.ant-form-vertical .item-label-row .ant-form-item {
  display: flex; }
  form.ant-form-vertical .item-label-row .ant-form-item .ant-form-item-label {
    margin: auto 0;
    padding: 0; }

form .w-100 .ant-form-item-control-wrapper {
  width: 100%; }

form.shrinked .ant-form-item:not(.form-item-textarea) {
  max-width: 370px;
  width: 100%; }
  form.shrinked .ant-form-item:not(.form-item-textarea) .ant-calendar-picker,
  form.shrinked .ant-form-item:not(.form-item-textarea) .ant-form-item-children {
    max-width: 370px; }

form .ant-form-item-label label,
form .ant-form-item-label {
  margin-bottom: 8px; }

form .ant-form-item-label label,
form .ant-form-item-label,
form .ant-radio-wrapper {
  font-size: 12px;
  line-height: normal;
  color: rgba(0, 0, 0, 0.5); }

form .vertical-radio .ant-radio-wrapper {
  display: block;
  padding: 8px 0; }

form .radio-checkbox-row {
  display: flex;
  margin: 16px 0 !important;
  padding-bottom: 0 !important;
  align-items: center; }
  form .radio-checkbox-row.reverse {
    flex-direction: row-reverse; }
    form .radio-checkbox-row.reverse .ant-checkbox-wrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center; }
      form .radio-checkbox-row.reverse .ant-checkbox-wrapper .ant-checkbox + span {
        padding-right: 40px;
        padding-left: 0px; }
  form .radio-checkbox-row > * {
    width: 100%;
    padding: 0; }
    form .radio-checkbox-row > *.ant-form-item-label {
      width: 60%; }

form .ant-input,
form .ant-select-auto-complete.ant-select .ant-input,
form .ant-select-auto-complete.ant-select .ant-input {
  height: 40px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 14px; }
  form .ant-input:hover,
  form .ant-select-auto-complete.ant-select .ant-input:hover,
  form .ant-select-auto-complete.ant-select .ant-input:hover {
    border-color: #149566 !important; }
  form .ant-input:disabled,
  form .ant-select-auto-complete.ant-select .ant-input:disabled,
  form .ant-select-auto-complete.ant-select .ant-input:disabled {
    color: #000;
    border-color: #d9d9d9 !important; }
  form .ant-input:focus,
  form .ant-select-auto-complete.ant-select .ant-input:focus,
  form .ant-select-auto-complete.ant-select .ant-input:focus {
    border-color: #149566 !important;
    box-shadow: 0 0 0 2px rgba(20, 149, 102, 0.2); }

form .ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: 40px; }

form .ant-input textarea.ant-input {
  padding: 16px 20px;
  color: #000; }

form .has-error .ant-form-explain,
form .has-error .ant-form-split {
  position: relative;
  text-align: left;
  color: #ff0101;
  font-size: 12px; }
  form .has-error .ant-form-explain span,
  form .has-error .ant-form-split span {
    display: block;
    position: absolute;
    min-width: 300px; }

form .has-error .ant-input,
form .has-error .ant-input:hover {
  border-color: #ff0101; }

form .ant-form-explain {
  margin-top: 4px; }

.ant-form-item--inline {
  display: flex;
  align-items: center; }
  .ant-form-item--inline .ant-form-item-label {
    padding: 0;
    width: 100%;
    max-width: 217px;
    line-height: normal; }

.ant-checkbox-wrapper {
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px; }

.ant-checkbox-inner {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.1); }

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: rgba(0, 0, 0, 0.1) !important; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.1); }

.ant-checkbox-checked .ant-checkbox-inner:after {
  border: 2px solid #149566;
  border-top: 0;
  border-left: 0; }

.ant-checkbox-inner:after {
  left: 5px;
  top: 10px;
  display: table;
  width: 8px;
  height: 14px; }

.ant-checkbox-group {
  margin-top: 4px; }

.ant-checkbox-group-item {
  margin-right: 16px; }

.order-checkbox-reverse .ant-checkbox-group-item {
  display: inline-flex;
  align-items: center;
  padding: 5px 0;
  margin-right: 64px; }

.order-checkbox-reverse .ant-checkbox {
  order: 2; }

.order-checkbox-reverse .ant-checkbox + span {
  padding-right: 20px;
  padding-left: 0; }

.ant-form label {
  font-size: 12px; }

.ant-select-selection {
  height: 40px;
  border-color: rgba(0, 0, 0, 0.1); }
  .ant-select-selection:hover {
    border-color: #149566; }

.ant-select-selection__rendered {
  margin-left: 20px;
  margin-right: 11px;
  line-height: 40px; }

.ant-select {
  color: #000; }

.ant-select-disabled .icon {
  opacity: 0.3; }

.ant-select-selection--multiple {
  padding: 4px 11px; }
  .ant-select-selection--multiple .ant-select-selection__rendered {
    transform: translateY(50px);
    margin-right: -15px;
    margin-left: -10px; }
    .ant-select-selection--multiple .ant-select-selection__rendered ul {
      display: flex;
      flex-wrap: wrap; }
    .ant-select-selection--multiple .ant-select-selection__rendered li {
      float: unset; }
      .ant-select-selection--multiple .ant-select-selection__rendered li:not(.ant-select-search) {
        height: 40px;
        border-radius: 4px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
        background-color: #ffffff;
        padding: 8px 10px 8px 16px;
        font-size: 12px;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        margin-right: 16px; }
        .ant-select-selection--multiple .ant-select-selection__rendered li:not(.ant-select-search) .ant-select-selection__choice__remove {
          position: unset;
          margin-left: 5px; }
      .ant-select-selection--multiple .ant-select-selection__rendered li.ant-select-search {
        position: absolute;
        transform: translate(15px, -50px); }

@media (max-width: 767px) {
  .search-row > div {
    text-align: center; }
    .search-row > div:first-child {
      margin-bottom: 15px; }
      .search-row > div:first-child .ant-col-12 {
        padding: 0 5px !important; } }

@media (min-width: 768px) and (max-width: 900px) {
  .search-row .ant-btn.medium {
    width: 130px; } }

.select-naked .ant-select-selection {
  padding-right: 10px; }

.select-naked .ant-select-selection__rendered {
  margin: 0;
  line-height: normal; }

.select-naked .ant-select-selection {
  height: auto;
  border: none;
  color: #149566; }
  .select-naked .ant-select-selection:active, .select-naked .ant-select-selection:focus {
    box-shadow: none; }

.select-naked .ant-select-selection__placeholder {
  color: #149566; }

.select-naked .ant-select-arrow {
  right: 0;
  top: 1px; }

.select-naked .ant-select-arrow-icon {
  font-size: 16px;
  fill: #149566; }

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #149566;
  box-shadow: 0 0 0 2px rgba(20, 149, 102, 0.2); }

.ant-form-item-control {
  line-height: 1; }
  .ant-form-item-control .anticon {
    color: #149566;
    fill: #149566; }

.anticon-calendar {
  font-size: 21px; }

.ant-calendar-picker-icon,
.ant-select-arrow,
.ant-calendar-picker-clear {
  display: inline-flex;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto; }

.ant-calendar-picker-input.ant-input-disabled + .ant-calendar-picker-icon {
  opacity: 0.3; }

.ant-calendar-picker-clear {
  font-size: 13px !important;
  transform: translate(-30px, 1px); }

.ant-select-arrow-icon {
  font-size: 24px; }

.ant-calendar-picker,
.ant-form-item-children {
  width: 100%; }

.ant-radio-group.layout-vertical .ant-radio-wrapper {
  display: block;
  margin: 20px 0; }

.ant-radio-group .ant-radio-wrapper {
  display: inline-flex;
  align-items: center; }

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner,
.ant-radio-checked::after {
  border-color: #149566; }

.ant-radio-inner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 1px rgba(0, 0, 0, 0.1); }
  .ant-radio-inner:after {
    width: 16px;
    height: 16px;
    margin: auto;
    border-radius: 50%;
    background-color: #149566; }

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none; }

.custom-tags-box .ant-tag,
.custom-tags-box .ant-tag a,
.custom-tags-box .ant-tag a:hover {
  color: rgba(0, 0, 0, 0.8);
  font-size: 12px; }

.custom-tags-box .ant-tag {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  margin-right: 16px;
  padding: 8px 10px 8px 16px;
  font-size: 12px;
  line-height: normal;
  border: none; }

.custom-tags-box .ant-tag .anticon-close {
  font-size: 16px;
  margin-left: 14px;
  color: #149566; }

.indicator {
  display: inline-flex;
  align-items: center; }
  .indicator .icon {
    font-size: 20px;
    margin: 0 8px;
    fill: currentColor; }

.indicator--active {
  color: #149566; }

.indicator--inactive {
  color: #f2203a; }

.indicator--cancelled {
  color: rgba(0, 0, 0, 0.3); }

.calendar-grey-large {
  font-size: 56px;
  fill: rgba(0, 0, 0, 0.5); }

.ant-calendar a:hover {
  color: #149566 !important; }

.ant-calendar .ant-calendar-table .ant-calendar-selected-date > div {
  background: #149566; }

.ant-calendar .ant-calendar-table .ant-calendar-date:hover {
  background: rgba(20, 149, 102, 0.1); }

.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
  color: #149566; }
  .ant-calendar .ant-calendar-today-btn:hover,
  .ant-calendar .ant-calendar-clear-btn:hover {
    color: #17ab75; }

.ant-calendar .ant-calendar-today .ant-calendar-date {
  color: #595959;
  border-color: #149566; }

.ant-calendar .ant-calendar-selected-day .ant-calendar-date {
  background: none; }

.ant-calendar .ant-calendar-decade-panel-decade:hover,
.ant-calendar .ant-calendar-year-panel-year:hover,
.ant-calendar .ant-calendar-month-panel-month:hover {
  background: rgba(20, 149, 102, 0.1); }

.ant-calendar .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
.ant-calendar .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.ant-calendar .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  background: #149566; }
  .ant-calendar .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover,
  .ant-calendar .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover,
  .ant-calendar .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
    color: #ffffff !important; }

.form-collapse .ant-collapse-item {
  border-bottom: none; }

.form-collapse .ant-collapse-content-box {
  padding-left: 0px;
  padding-right: 0px; }

.cancel-red-large {
  font-size: 63px;
  fill: #f2203a; }

.currency-input .ant-form-item-children::before {
  content: '£';
  display: table;
  position: absolute;
  transform: translate(13px, 7px);
  color: #b2b2b2;
  font-size: 18px;
  font-weight: 600;
  z-index: 50; }

.currency-input input {
  padding-left: 30px;
  width: 245px; }

.fully-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.floating-loading {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15); }

.no-data {
  font-size: 0.9em;
  margin: 8px 0;
  opacity: 0.8; }

.info-span {
  text-align: center;
  font-size: 12px;
  font-style: italic;
  padding: 0 40px;
  margin-top: 0.75em; }

.svg-grey {
  fill: rgba(0, 0, 0, 0.3); }

.svg-green {
  fill: #149566; }

.svg-hover-green:hover {
  fill: #149566; }

.w-50 .ant-input-number {
  width: 100%; }

.customer_auto_orders_gridview .ant-calendar-picker-input.ant-input {
  height: 24px; }

.customer_auto_orders_gridview .ant-checkbox-inner,
.customer_auto_orders_gridview .ant-checkbox-input {
  transform: scale(0.8); }

.customer_auto_orders_gridview .ant-spin-dot-item {
  background-color: #149566; }

.customer_auto_orders_gridview .ant-spin-text {
  color: #149566; }

.customer_auto_orders_gridview .ant-input-number-focused {
  border-color: #149566; }

.customer_auto_orders_gridview .ant-input-number:hover {
  border-color: #149566; }

.customer_summary_sidebar .ant-calendar-picker-input.ant-input {
  height: 24px; }

.customer_summary_sidebar .ant-checkbox-inner,
.customer_summary_sidebar .ant-checkbox-input {
  transform: scale(0.8); }

.customer_summary_sidebar .ant-spin-dot-item {
  background-color: #149566; }

.customer_summary_sidebar .ant-spin-text {
  color: #149566; }

.customer_summary_gridview .ant-calendar-picker-input.ant-input {
  height: 24px; }

.customer_summary_gridview .ant-checkbox-inner {
  width: 20px;
  height: 20px; }
  .customer_summary_gridview .ant-checkbox-inner::after {
    width: 6px;
    height: 10.14px; }

.customer_summary_gridview .ant-input-number-focused {
  border-color: #149566; }

.customer_summary_gridview .ant-input-number:hover {
  border-color: #149566; }

.customer_summary_gridview .ant-spin-dot-item {
  background-color: #149566; }

.customer_summary_gridview .ant-spin-text {
  color: #149566; }

.customer_summary_gridview .ant-spin-nested-loading,
.customer_summary_gridview .ant-spin-container {
  height: 100%;
  width: 100%; }

.gridview-spin-container > .ant-spin-container {
  align-items: flex-start;
  padding: 0 35px 0 20px; }

.customer_scripts_gridview .ant-calendar-picker-input.ant-input {
  height: 24px; }

.customer_scripts_gridview .ant-checkbox-inner {
  width: 20px;
  height: 20px; }
  .customer_scripts_gridview .ant-checkbox-inner::after {
    width: 6px;
    height: 10.14px; }

.customer_scripts_gridview .ant-spin-dot-item {
  background-color: #149566; }

.customer_scripts_gridview .ant-spin-text {
  color: #149566; }

.issue_letter_modal .ant-checkbox-inner {
  width: 20px;
  height: 20px; }
  .issue_letter_modal .ant-checkbox-inner::after {
    width: 6px;
    height: 10.14px; }

.issue_letter_modal .ant-spin-dot-item {
  background-color: #149566; }

.issue_letter_modal .ant-spin-text {
  color: #149566; }

.upload_script_modal .slick-slider {
  height: 100%;
  width: 100%; }

.upload_script_modal .slick-list {
  height: 100%;
  width: 100%; }

.upload_script_modal .ant-upload-picture-card-wrapper {
  height: 100%; }

.upload_script_modal .ant-upload-list-picture-card-container {
  height: 100%;
  width: 100%; }

.upload_script_modal .ant-upload-list-picture-card {
  height: 100%;
  width: 100%; }

.upload_script_modal .ant-upload-list-item {
  height: 100%;
  width: 100%; }

.upload_script_modal .ant-carousel {
  height: 100%;
  width: 100%; }

.upload_script_modal .slick-track {
  height: 100%;
  width: 100%; }

.upload_script_modal .slick-dots li button {
  background-color: grey; }

.upload_script_modal .slick-dots li.slick-active button {
  background: black; }

.upload_script_modal .slick-slide {
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 30px; }
  .upload_script_modal .slick-slide > div {
    height: 100% !important;
    width: 100%; }

.upload_script_modal .ant-upload-list-item-image {
  object-fit: contain !important; }

.upload_script_modal .ant-calendar-picker-input.ant-input {
  height: 24px; }

.upload_script_modal .ant-calendar-picker {
  height: 24px; }

.upload_script_modal .ant-spin-dot-item {
  background-color: #149566; }

.upload_script_modal .ant-spin-text {
  color: #149566; }

.upload_script_modal .ant-spin-nested-loading,
.upload_script_modal .ant-spin-container {
  height: 100%;
  width: 100%; }

.image_capture_modal {
  position: flex !important;
  width: 1000px !important;
  align-items: center;
  justify-content: center; }

.order_prescription_info_tab .ant-calendar-picker-input.ant-input {
  height: 24px; }

.order_prescription_info_tab .ant-calendar-picker {
  height: 24px; }

.order_prescription_info_tab [class*="ant-row"] * {
  pointer-events: none; }

.new_letter .ant-checkbox-inner {
  width: 20px;
  height: 20px; }
  .new_letter .ant-checkbox-inner::after {
    width: 6px;
    height: 10.14px; }

.new_letter .ant-input-number-input-wrap,
.new_letter .ant-input-number-input {
  height: 24px !important; }

.new_letter .ant-input-number-focused {
  border-color: #149566; }

.new_letter .ant-input-number:hover {
  border-color: #149566; }

.prescription_matcher_script .slick-slider {
  height: 100%;
  width: 100%; }

.prescription_matcher_script .slick-list {
  height: 100%;
  width: 100%; }

.prescription_matcher_script .ant-upload-picture-card-wrapper {
  height: 100%; }

.prescription_matcher_script .ant-upload-list-picture-card-container {
  height: 100%;
  width: 100%; }

.prescription_matcher_script .ant-upload-list-picture-card {
  height: 100%;
  width: 100%; }

.prescription_matcher_script .ant-upload-list-item {
  height: 100%;
  width: 100%; }

.prescription_matcher_script .ant-carousel {
  height: 100%;
  width: 100%; }

.prescription_matcher_script .slick-track {
  height: 100%;
  width: 100%; }

.prescription_matcher_script .slick-dots li button {
  background-color: grey; }

.prescription_matcher_script .slick-dots li.slick-active button {
  background: black; }

.prescription_matcher_script .slick-slide {
  display: flex !important;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 30px; }
  .prescription_matcher_script .slick-slide > div {
    height: 100% !important;
    width: 100%; }

.prescription_matcher_script .ant-upload-list-item-image {
  object-fit: contain !important; }

.prescription_matcher_script .ant-calendar-picker-input.ant-input {
  height: 24px; }

.prescription_matcher_script .ant-calendar-picker {
  height: 24px; }

.prescription_matcher_script .ant-spin-dot-item {
  background-color: #149566; }

.prescription_matcher_script .ant-spin-text {
  color: #149566; }

.prescription_matcher_script .ant-spin-nested-loading,
.prescription_matcher_script .ant-spin-container {
  height: 100%;
  width: 100%; }

.prescription_matcher .ant-spin-nested-loading,
.prescription_matcher .ant-spin-container {
  height: 100%;
  width: 100%; }

.prescription_matcher_balance_letter .ant-checkbox-inner {
  width: 20px;
  height: 20px; }
  .prescription_matcher_balance_letter .ant-checkbox-inner::after {
    width: 6px;
    height: 10.14px; }

.prescription_matcher_balance_letter .ant-spin-dot-item {
  background-color: #149566; }

.prescription_matcher_balance_letter .ant-spin-text {
  color: #149566; }

.prescription_matcher_patient .ant-spin-dot-item {
  background-color: #149566; }

.prescription_matcher_patient .ant-spin-text {
  color: #149566; }

.prescription_matcher_order_item_quickfind .ant-spin-dot-item {
  background-color: #149566; }

.prescription_matcher_order_item_quickfind .ant-spin-text {
  color: #149566; }

.prescription_matched_order_item .ant-spin-dot-item {
  background-color: #149566; }

.prescription_matched_order_item .ant-spin-text {
  color: #149566; }

.customer_search_input {
  width: 100%; }

.customer_search_modal .ant-modal-body {
  padding: 24px; }

.prescription_missing_prescription_item .ant-spin-dot-item {
  background-color: #149566; }

.prescription_missing_prescription_item .ant-spin-text {
  color: #149566; }

.add_missing_products_modal {
  position: flex !important;
  width: 1200px !important;
  height: 400px !important;
  align-items: center;
  justify-content: center; }
  .add_missing_products_modal .ant-modal-content,
  .add_missing_products_modal .ant-modal-body {
    width: 100%;
    height: 100%; }

@media screen and (min-width: 768px) {
  .select_customer_tab {
    min-width: 800px !important;
    padding-right: 15px !important;
    padding-left: 15px !important; } }

.break-work {
  overflow-wrap: break-word; }

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
