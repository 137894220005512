.container {
    min-width: 25%;
    padding: 20px;
    background-color: transparent;

    table {
        width: 100%;

        thead {
            border-top: 1px solid rgb(160, 174, 192);
            margin-top: 15px;

            th {
                padding: 5px;
                font-size: 10px;
                font-weight: bold;
                color: rgb(160, 174, 192);
                text-transform: uppercase;
            }
        }

        tbody {
            tr {
                border-top: 1px solid rgb(160, 174, 192);

                td {
                    padding: 5px;
                    font-size: 14px;
                    color: #2d3748;
                }

                .order-id {
                    font-weight: bold;
                }
            }
        }
    }
}

.checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-bottom: 10px;
}