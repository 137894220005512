.order-summary-modal-container {
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .07);
    border-radius: 4px;

    .order-summary-header {
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #f8f9fa;

        h3 {
            font-size: 10px;
            font-weight: bold;
            color: #a0aec0;
            text-transform: uppercase;
            margin: 0;

            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }

        .padding-left {
            padding-left: 6px;
        }
    }

    .order-summary-body {
        .summary-row {
            display: flex;
            padding: 5px 0;

            .align-right {
                display: flex;
                justify-content: flex-end;
            }

            h5 {
                font-size: 14px;
                font-weight: 600;
                margin: 0;
                padding-right: 6px;
            }

            .background-yellow {
                background-color: yellow;
            }

            .font-bold {
                font-weight: 600;
            }

            .padding-left {
                padding-left: 6px;
            }
        }

        .script-history-section {
            padding-bottom: 20px;
        }
    }
}