.menu__wrap {
  position: fixed;
  top: 0;
  right: 100vw;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  color: #000;
  font-weight: normal;
  transition: right 0.3s ease-in-out; }
  .menu__wrap .menu__backdrop {
    display: inline-block;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.5); }
  .menu__wrap.true {
    right: 0; }
    .menu__wrap.true .menu__backdrop {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1; }
    .menu__wrap.true .menu__inner {
      left: 0; }

.menu__inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 280px;
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  position: relative;
  text-align: center;
  padding: 40px 0;
  background-color: #fff;
  transition: left 0.3s ease;
  box-shadow: 4px 0 14px 0 rgba(0, 0, 0, 0.1); }
  .menu__inner .menu__user {
    margin: 10px 0 30px; }

.menu__close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1001; }
  .menu__close .icon {
    font-size: 20px;
    fill: #149566 !important; }

.menu__change {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .menu__change a {
    display: flex;
    align-items: center; }
  .menu__change .icon {
    font-size: 21px;
    margin-right: 16px;
    fill: currentColor; }

.menu__label {
  font-size: 12px;
  text-align: left; }

.menu__nav {
  margin: 40px 0 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0; }
  .menu__nav li {
    list-style-type: none; }

.menu__wrap .menu__navlink {
  display: flex;
  align-items: center;
  padding: 14px 14px 14px 36px;
  color: inherit;
  text-transform: uppercase; }

.menu__nav li {
  color: #b2b2b2; }
  .menu__nav li.is-active {
    color: #149566; }
  .menu__nav li:not(.is-active):not(.menu__logout):hover {
    color: #b2b2b2;
    background-color: rgba(0, 0, 0, 0.04); }

.menu__nav-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin-right: 15px; }
  .menu__nav-icon .icon {
    font-size: 21px; }
  .menu__nav-icon .icon-log-out {
    font-size: 18px; }

.menu__logout {
  margin-top: auto; }
  .menu__logout .menu__navlink {
    text-transform: lowercase; }
    .menu__logout .menu__navlink span::first-letter {
      text-transform: uppercase; }

.profile {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.profile__avatar {
  width: 56px;
  height: 56px;
  object-fit: contain;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px rgba(0, 0, 0, 0.1); }
  .profile__avatar img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.profile__username {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600; }
