.orders .ant-table-wrapper {
  margin-top: 24px; }

.table__selection {
  display: flex;
  align-items: center;
  justify-content: flex-end; }

.table__selection-items {
  display: flex;
  align-items: center;
  margin-left: 24px;
  padding: 0 10px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.75); }
  .table__selection-items .icon {
    color: #149566;
    margin-right: 8px; }

.table__selection-item {
  display: flex;
  align-items: center;
  padding: 12px 14px; }

.ant-table-tbody > tr > td:last-child {
  padding-right: 20px; }

.section__header .ant-select-selection {
  background-color: transparent; }

.ant-form-horizontal .ant-form-item {
  margin: 0 !important; }
