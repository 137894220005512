.scripts-modal-container {

    .scripts-processing-header {
        margin-bottom: 20px;

        label {
            margin-right: 8px;
            color: #2d3748;
        }
    }

    .scripts-rows-container {
        width: 100%;
        border-radius: 4px;
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.07);

        .scripts-header {
            width: 100%;
            display: flex;
            align-items: center;
            height: 50px;

            h5 {
                padding: 5px 10px;
                font-size: 10px;
                font-weight: bold;
                color: #a0aec0;
                text-transform: uppercase;
                margin: 0;

                &:first-child {
                    padding-left: 20px;
                }

                &:last-child {
                    padding-right: 20px;
                }
            }
        }

        .scripts-body {
            width: 100%;
            margin-bottom: 30px;

            .script-row {
                width: 100%;
                display: flex;
                align-items: center;

                .item-wrapper {
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    align-items: center;

                    &:first-child {
                        padding-left: 20px;
                    }

                    &:last-child {
                        padding-right: 20px;
                    }
                }

                .select-wrapper {
                    padding: 10px;

                    .ant-select-selection__rendered {
                        margin-left: 10px;
                    }
                }

                &:last-child {
                    margin-bottom: 10px;
                }

                .ant-select-arrow-icon {
                    font-size: 12px;
                }

                .ant-checkbox-wrapper {
                    margin: 0;
                }
            }

            .oddRow {
                background-color: #fff;
            }

            .evenRow {
                background-color: rgba(0, 0, 0, .03);
            }
        }
    }

    .scripts-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
    }
}