.header {
  background-color: #149566;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  color: #ffffff; }
  .header a {
    color: currentColor; }
  .header .icon {
    fill: currentColor; }
  .header .icon-menu {
    font-size: 48px;
    margin-right: 6px; }
  .header .icon-bell {
    font-size: 19px; }
  .header .icon-new-order {
    font-size: 16px; }

.header__inner {
  padding: 0 16px; }

.header__title {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: inherit;
  text-transform: capitalize; }

.backlink .icon {
  font-size: 32px; }
