.order-list-container {}

.dispatch-order-items-confirm-button {
    border: 0;
    outline: 0;
    width: 42px;
    margin-top: 7px;
    margin-bottom: 2px;
    align-items: 'center';
    justify-content: 'center';
    font-size: 8px;
    color: white;
    all: 'unset';
    display: 'flex';
    background-color: #008001;
    border-radius: 4px;
    padding: 5px;

    &:disabled {
        background-color: rgba(0, 128, 1, 0.48);
        cursor: not-allowed;
    }
}

.view-order-items-button {
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    margin-left: 6px;
}

.view-order-items-icon {
    width: 15px;
    height: 15px;
}